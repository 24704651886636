import { toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
import React, { lazy } from "react";
import { Route, Routes, useNavigate } from "react-router-dom"; //, useLocation

import NavMenu from "./Common/NavMenu/NavMenu";

import Landing from "./Components/Landing/Landing";
import Login from "./Components/Login/Login";
import Logout from "./Components/Login/Logout";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";

import "./App.css";
import { FEATURES } from "./Auth/AuthCheck";
import ErrorAlert from "./Common/ErrorAlert/ErrorAlert";
import LogoutRedirectModal from "./Common/Modals/LogoutRedirectModal";
import { API_PATHS } from "./Common/Utilities/constants";

// Feature constants
const { USERS, SCANS, ORDERS, COMMON } = FEATURES;
const { ORDERS_SEARCH, ACCESSION_SCAN, USERS_PROFILE } = API_PATHS;

const Orders = lazy(() => import("./Components/Orders/Orders"));
const Reconciliation = lazy(() =>
  import("./Components/Reconciliation/Reconciliation")
);
const Scans = lazy(() => import("./Components/Scans/Scans"));
const Users = lazy(() => import("./Components/Users/Users"));

/*
----------------------------- Notes -------------------------------------
1. Applied route protection. 
*/

function App({ oktaAuth, oktaSignInConfig }) {
  const navigate = useNavigate();

  const originalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  // --------------------------------Return JSX -----------------------------------------
  return (
    <div className="App">
      <Security oktaAuth={oktaAuth} restoreOriginalUri={originalUri}>
        <NavMenu />
        <main>
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute
                  routeFeature={COMMON}
                  keyCapability={{
                    api: USERS_PROFILE,
                    accessLevel: "POST",
                  }}
                />
              }
            >
              <Route path="" element={<Landing />} />
            </Route>
            <Route
              path="/login"
              element={<Login config={oktaSignInConfig} />}
            />
            <Route path="/logout" element={<Logout />} />
            <Route path="/login/callback" element={<LoginCallback />} />
            <Route
              path="/orders"
              element={
                <ProtectedRoute
                  routeFeature={ORDERS}
                  keyCapability={{
                    api: ORDERS_SEARCH,
                    accessLevel: "POST",
                  }}
                />
              }
            >
              <Route path="" element={<Orders />} />
            </Route>
            <Route
              path="/scans"
              element={
                <ProtectedRoute
                  routeFeature={SCANS}
                  keyCapability={{
                    api: ACCESSION_SCAN,
                    accessLevel: "POST",
                  }}
                />
              }
            >
              <Route path="" element={<Scans />} />
            </Route>
            <Route
              path="/reconciliation"
              element={
                <ProtectedRoute
                  routeFeature={SCANS}
                  keyCapability={{
                    api: ACCESSION_SCAN,
                    accessLevel: "POST",
                  }}
                />
              }
            >
              <Route path="" element={<Reconciliation />} />
            </Route>
            <Route
              path="/users"
              element={
                <ProtectedRoute
                  routeFeature={USERS}
                  keyCapability={{
                    api: API_PATHS.USERS,
                    accessLevel: "GET",
                  }}
                />
              }
            >
              <Route path="" element={<Users />} />
            </Route>
          </Routes>
        </main>
        <ErrorAlert />
        <LogoutRedirectModal />
      </Security>
    </div>
  );
}

export default App;
